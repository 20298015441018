import {lazy} from 'react'

const ForgetForm = lazy(() => import(/* webpackChunkName: "forget-form" */ './forget-form'))
const LoginForm = lazy(() => import(/* webpackChunkName: "login-form" */ './login-form'))
const RegisterForm = lazy(() => import(/* webpackChunkName: "register-form" */ './register-form'))
const LogisterPage = lazy(() => import(/* webpackChunkName: "logister-page" */ './logister-page'))
const ResetPasswordForm = lazy(() => import(/* webpackChunkName: "reset-password-form" */ './reset-password-form'))
const FindEmailForm = lazy(() => import(/* webpackChunkName: "find-email-form" */ './find-email-form'))

export {ForgetForm, LoginForm, RegisterForm, LogisterPage, ResetPasswordForm, FindEmailForm}
