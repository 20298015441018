import React, {PureComponent, Suspense} from 'react'
import {HashRouter, Route, Switch} from 'react-router-dom'
import {I18nextProvider} from 'react-i18next'
import config from 'config'
import {PROJECTS} from 'const'
import ScrollToTop from 'components/ScrollToTop'
import GoogleAnalyticsService from 'services/GoogleAnalyticsService'
import {getClient} from 'theme/brands/brandUtils'

import i18n from 'i18n-client'

import {
  ForgetForm,
  LoginForm,
  LogisterPage,
  RegisterForm,
  ResetPasswordForm,
  FindEmailForm,
} from './HostedLoginAsyncRoutes'

class HostedLogin extends PureComponent {
  render() {
    GoogleAnalyticsService.pushToGTMDataLayer({media_title: getClient()})
    const isRomania = config.project === PROJECTS.RCRO
    return (
      <I18nextProvider i18n={i18n}>
        <HashRouter>
          <Suspense fallback="">
            <ScrollToTop>
              <Switch>
                <Route exec path="/forget" component={ForgetForm} />
                <Route exec path="/resetpassword" component={ResetPasswordForm} />
                {isRomania && <Route exec path="/find-email" component={FindEmailForm} />}
                <Route exec path="/login" component={LoginForm} />
                <Route exec path="/register" component={RegisterForm} />
                <Route component={LogisterPage} />
              </Switch>
            </ScrollToTop>
          </Suspense>
        </HashRouter>
      </I18nextProvider>
    )
  }
}

export {HostedLogin as PureHostedLogin}

export default HostedLogin
